import { j as l } from "./jsx-runtime-B6kdoens.js";
import { _ as c } from "./index-28E3tTLh.js";
import { forwardRef as s, createElement as i } from "react";
import { $ as m } from "./index-CZBInFmv.js";
import { c as u } from "./utils-CJ9afRe1.js";
import { c as b } from "./index-BV2fynjD.js";
const x = /* @__PURE__ */ s((t, r) => /* @__PURE__ */ i(m.label, c({}, t, {
  ref: r,
  onMouseDown: (e) => {
    var a;
    (a = t.onMouseDown) === null || a === void 0 || a.call(t, e), !e.defaultPrevented && e.detail > 1 && e.preventDefault();
  }
}))), p = x, $ = b(
  "leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      truncate: {
        true: "truncate"
      },
      required: {
        true: "truncate"
      },
      size: {
        sm: "text-xs",
        md: "text-sm",
        lg: "text-base"
      },
      variant: {
        default: "text-black-primary",
        secondary: "text-gray-neutral-70",
        destructive: "text-red-500"
      },
      fontWeight: {
        normal: "font-normal",
        default: "font-medium",
        bold: "font-bold"
      },
      defaultVariants: {
        variant: "default",
        fontWeight: "default",
        truncate: !1,
        size: "md",
        required: !1
      }
    }
  }
), v = s(({ className: t, variant: r, fontWeight: e, truncate: a, required: o, size: d, ...n }, f) => /* @__PURE__ */ l.jsxs(
  p,
  {
    ref: f,
    className: u($({ size: d, required: o, variant: r, truncate: a, fontWeight: e, className: t })),
    ...n,
    children: [
      n.children,
      o && /* @__PURE__ */ l.jsx("span", { className: "text-blue-100", children: "*" })
    ]
  }
));
v.displayName = "Label";
export {
  v as L
};
