import { _ as p, c as W } from "./index-28E3tTLh.js";
import * as b from "react";
import { forwardRef as f, useRef as D, createElement as c, useCallback as q } from "react";
import { b as g, $ as M } from "./index-CTDw4Pi4.js";
import { $ as B } from "./index-Cp-Nu9_j.js";
import { $ as H } from "./index-CZBInFmv.js";
import { $ as v, b as J, c as Q, d as V, e as X, f as Y, g as Z, h as ee, i as oe, j as ne, k as te, l as re, a as ae, m as ce, n as de, p as se, o as pe } from "./index-DMYLQVGn.js";
import { $ as _ } from "./index-rKUeoTl7.js";
import { j as s } from "./jsx-runtime-B6kdoens.js";
import { c as u } from "./utils-CJ9afRe1.js";
import { I as h } from "./Icon-BmXCLOWx.js";
const y = "DropdownMenu", [ie, to] = B(y, [
  v
]), i = v(), [fe, N] = ie(y), $e = (e) => {
  const { __scopeDropdownMenu: o, children: n, dir: t, open: r, defaultOpen: a, onOpenChange: l, modal: d = !0 } = e, $ = i(o), w = D(null), [m = !1, x] = M({
    prop: r,
    defaultProp: a,
    onChange: l
  });
  return /* @__PURE__ */ c(fe, {
    scope: o,
    triggerId: _(),
    triggerRef: w,
    contentId: _(),
    open: m,
    onOpenChange: x,
    onOpenToggle: q(
      () => x(
        (U) => !U
      ),
      [
        x
      ]
    ),
    modal: d
  }, /* @__PURE__ */ c(te, p({}, $, {
    open: m,
    onOpenChange: x,
    dir: t,
    modal: d
  }), n));
}, le = "DropdownMenuTrigger", ue = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, disabled: t = !1, ...r } = e, a = N(le, n), l = i(n);
  return /* @__PURE__ */ c(re, p({
    asChild: !0
  }, l), /* @__PURE__ */ c(H.button, p({
    type: "button",
    id: a.triggerId,
    "aria-haspopup": "menu",
    "aria-expanded": a.open,
    "aria-controls": a.open ? a.contentId : void 0,
    "data-state": a.open ? "open" : "closed",
    "data-disabled": t ? "" : void 0,
    disabled: t
  }, r, {
    ref: W(o, a.triggerRef),
    onPointerDown: g(e.onPointerDown, (d) => {
      !t && d.button === 0 && d.ctrlKey === !1 && (a.onOpenToggle(), a.open || d.preventDefault());
    }),
    onKeyDown: g(e.onKeyDown, (d) => {
      t || ([
        "Enter",
        " "
      ].includes(d.key) && a.onOpenToggle(), d.key === "ArrowDown" && a.onOpenChange(!0), [
        "Enter",
        " ",
        "ArrowDown"
      ].includes(d.key) && d.preventDefault());
    })
  })));
}), be = (e) => {
  const { __scopeDropdownMenu: o, ...n } = e, t = i(o);
  return /* @__PURE__ */ c(ae, p({}, t, n));
}, me = "DropdownMenuContent", xe = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = N(me, n), a = i(n), l = D(!1);
  return /* @__PURE__ */ c(J, p({
    id: r.contentId,
    "aria-labelledby": r.triggerId
  }, a, t, {
    ref: o,
    onCloseAutoFocus: g(e.onCloseAutoFocus, (d) => {
      var $;
      l.current || ($ = r.triggerRef.current) === null || $ === void 0 || $.focus(), l.current = !1, d.preventDefault();
    }),
    onInteractOutside: g(e.onInteractOutside, (d) => {
      const $ = d.detail.originalEvent, w = $.button === 0 && $.ctrlKey === !0, m = $.button === 2 || w;
      (!r.modal || m) && (l.current = !0);
    }),
    style: {
      ...e.style,
      "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
      "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
      "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), ge = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(ce, p({}, r, t, {
    ref: o
  }));
}), we = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(Q, p({}, r, t, {
    ref: o
  }));
}), he = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(V, p({}, r, t, {
    ref: o
  }));
}), _e = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(X, p({}, r, t, {
    ref: o
  }));
}), De = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(de, p({}, r, t, {
    ref: o
  }));
}), Me = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(Y, p({}, r, t, {
    ref: o
  }));
}), ve = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(Z, p({}, r, t, {
    ref: o
  }));
}), ye = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(ee, p({}, r, t, {
    ref: o
  }));
}), Ne = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(se, p({}, r, t, {
    ref: o
  }));
}), Ce = (e) => {
  const { __scopeDropdownMenu: o, children: n, open: t, onOpenChange: r, defaultOpen: a } = e, l = i(o), [d = !1, $] = M({
    prop: t,
    defaultProp: a,
    onChange: r
  });
  return /* @__PURE__ */ c(pe, p({}, l, {
    open: d,
    onOpenChange: $
  }), n);
}, Re = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(oe, p({}, r, t, {
    ref: o
  }));
}), Se = /* @__PURE__ */ f((e, o) => {
  const { __scopeDropdownMenu: n, ...t } = e, r = i(n);
  return /* @__PURE__ */ c(ne, p({}, r, t, {
    ref: o,
    style: {
      ...e.style,
      "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
      "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
      "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), Pe = $e, Oe = ue, je = be, Ie = xe, ke = ge, Ee = we, Te = he, Ae = _e, Ge = De, Ke = Me, C = ve, Le = ye, ze = Ne, Fe = Ce, Ue = Re, We = Se, R = ze;
R.displayName = "Arrow";
const S = b.forwardRef(({ className: e, children: o, checked: n, ...t }, r) => /* @__PURE__ */ s.jsxs(
  Ae,
  {
    ref: r,
    className: u(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    checked: n,
    ...t,
    children: [
      /* @__PURE__ */ s.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ s.jsx(C, { children: /* @__PURE__ */ s.jsx(h, { name: "Check" }) }) }),
      o
    ]
  }
));
S.displayName = "CheckboxItem";
const P = b.forwardRef(({ className: e, sideOffset: o = 4, withPortal: n, ...t }, r) => {
  const a = /* @__PURE__ */ s.jsx(
    Ie,
    {
      ref: r,
      sideOffset: o,
      className: u(
        "DropdownMenuContent z-dialog [data-disabled]:hidden p-4 gap-3 flex flex-col overflow-hidden overflow-y-auto scrollbar-stable rounded-md border bg-popover text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        e
      ),
      ...t
    }
  );
  return n ? /* @__PURE__ */ s.jsx(Be.Portal, { children: a }) : a;
});
P.displayName = "Content";
const O = ke;
O.displayName = "Group";
const j = b.forwardRef(({ className: e, inset: o, ...n }, t) => /* @__PURE__ */ s.jsx(
  Te,
  {
    ref: t,
    className: u(
      "relative flex cursor-pointer select-none items-center rounded-sm px-2 data-[selected=true]:bg-gray-neutral-30 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground hover:bg-accent min-h-9 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      o && "pl-8",
      e
    ),
    ...n
  }
));
j.displayName = "Item";
const I = b.forwardRef(({ className: e, inset: o, ...n }, t) => /* @__PURE__ */ s.jsx(
  Ee,
  {
    ref: t,
    className: u(
      "px-2 py-1.5 text-sm font-semibold",
      o && "pl-8",
      e
    ),
    ...n
  }
));
I.displayName = "Label";
const k = je;
k.displayName = "Portal";
const E = Ge;
E.displayName = "RadioGroup";
const T = b.forwardRef(({ className: e, children: o, ...n }, t) => /* @__PURE__ */ s.jsxs(
  Ke,
  {
    ref: t,
    className: u(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    ...n,
    children: [
      /* @__PURE__ */ s.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ s.jsx(C, { children: /* @__PURE__ */ s.jsx(h, { name: "Circle" }) }) }),
      o
    ]
  }
));
T.displayName = "RadioItem";
const A = b.forwardRef(({ className: e, ...o }, n) => /* @__PURE__ */ s.jsx(
  Le,
  {
    ref: n,
    className: u("-mx-1 h-px bg-gray-neutral-80", e),
    ...o
  }
));
A.displayName = "Separator";
const G = ({
  className: e,
  ...o
}) => /* @__PURE__ */ s.jsx(
  "span",
  {
    className: u("ml-auto text-xs tracking-widest opacity-60", e),
    ...o
  }
);
G.displayName = "Shortcut";
const K = Fe;
K.displayName = "Sub";
const L = b.forwardRef(({ className: e, ...o }, n) => /* @__PURE__ */ s.jsx(
  We,
  {
    ref: n,
    className: u(
      "z-tooltip min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      e
    ),
    ...o
  }
));
L.displayName = "SubContent";
const z = b.forwardRef(({ className: e, inset: o, children: n, ...t }, r) => /* @__PURE__ */ s.jsxs(
  Ue,
  {
    ref: r,
    className: u(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      o && "pl-8",
      e
    ),
    ...t,
    children: [
      n,
      /* @__PURE__ */ s.jsx(h, { name: "NavArrowRight", width: 24, height: 24 })
    ]
  }
));
z.displayName = "SubTrigger";
const F = Oe;
F.displayName = "Trigger";
const qe = Pe, Be = Object.assign(qe, {
  Arrow: R,
  Trigger: F,
  Group: O,
  Portal: k,
  Sub: K,
  RadioGroup: E,
  SubTrigger: z,
  SubContent: L,
  Content: P,
  Item: j,
  CheckboxItem: S,
  RadioItem: T,
  Label: I,
  Separator: A,
  Shortcut: G
});
export {
  Be as D
};
